import { Footer } from "../../components/Footer"
import { Header } from "../../components/Header"
import './Policy.css'

export const Policy = () => {
    return (
    <>
        <Header />
        <PolicyContent />
        <Footer />
    </>
    )
}

export const PolicyContent = () => {
    return (
    <main className="p-policy-main">
        <div id="policy" className="p-policy-header">
            <h2 className="p-policy-title">희망과학심리상담센터 서비스 이용약관</h2>
            <div className="p-policy-date-container__outer">
            <div className="p-policy-date-container">
                <select className="p-policy-date-container-select">
                <option value="0">2024.06.13</option>
                </select>
            </div>
            </div>
        </div>

        <div className="p-policy-link-container">
            <div className="p-policy-link-container__inner">
                <ol>
                    <li>
                        <a href="#제1장_총칙">제1장 총칙</a>
                    </li>
                    <li>
                        <a href="#제1조">제1조 (목적)</a>
                    </li>
                    <li>
                        <a href="#제2조">제2조 (약관의 효력과 변경)</a>
                    </li>
                    <li>
                        <a href="#제3조">제3조 (약관 외 준칙)</a>
                    </li>
                    <li>
                        <a href="#제4조">제4조 (용어의 정의)</a>
                    </li>
                    <li>
                        <a href="#제2장_회원_가입과_서비스_이용">제2장 회원 가입과 서비스 이용</a>
                    </li>
                    <li>
                        <a href="#제5조">제5조 (이용 계약의 성립)</a>
                    </li>
                    <li>
                        <a href="#제6조">제6조 (서비스 이용)</a>
                    </li>
                    <li>
                        <a href="#제7조">제7조 (서비스 제공의 중지)</a>
                    </li>
                    <li>
                        <a href="#제3장_책임">제3장 책임</a>
                    </li>
                    <li>
                        <a href="#제8조">제8조 (회사의 의무)</a>
                    </li>
                    <li>
                        <a href="#제9조">제9조 (이용자의 의무)</a>
                    </li>
                    <li>
                        <a href="#제4장_계약_해지_및_서비스_이용_제한">제4장 계약 해지 및 서비스 이용 제한</a>
                    </li>
                    <li>
                        <a href="#제10조">제10조 (계약 해지 및 이용 제한)</a>
                    </li>
                    <li>
                        <a href="#제11조">제11조 (이용 제한의 해제 절차)</a>
                    </li>
                    <li>
                        <a href="#제12조">제12조 (이용자의 게시물)</a>
                    </li>
                    <li>
                        <a href="#제5장_정보의_제공">제5장 정보의 제공</a>
                    </li>
                    <li>  
                        <a href="#제14조">제14조 (정보의 제공)</a>
                    </li>
                    <li>
                        <a href="#제15조">제15조 (지적 재산권)</a>
                    </li>
                    <li>  
                        <a href="#제16조">제16조 (서비스 내용의 추가 및 변경)</a>
                    </li>
                    <li>
                        <a href="#제6장_회사의_면책조항">제6장 회사의 면책조항</a>  
                    </li>
                    <li>
                        <a href="#제17조">제17조 (회사의 면책조항)</a>
                    </li>
                    <li>
                        <a href="#제7장_기타사항">제7장 기타사항</a>
                    </li>
                    <li>
                        <a href="#제18조">제18조 (재판권 및 준거법)</a>
                    </li>
                    <li>
                        <a href="#제19조">제19조 (이용자의 개인정보보호)</a>  
                    </li>
                </ol>
            </div>
        </div>

        <div className="p-policy-term-container">
            <h3 id="제1장_총칙">제1장 총칙</h3>

            <h4 id="제1조">제1조 (목적)</h4>
            <p>
            이 약관은 희망과학심리상담센터가 제공하는 인터넷 홈페이지(www.희망과학.com) 서비스의 이용조건 및 절차에 관한 사항과 기타 필요한 사항을 규정함을 목적으로 합니다.  
            </p>

            <h4 id="제2조">제2조 (약관의 효력과 변경)</h4>
            <ol>
                <li>
                    1. 이 약관은 이용자에게 공시함으로써 효력을 발생합니다.
                </li>
                <li>
                    2. 회사는 사정 변경의 경우와 운영상 중요한 사유가 있을 때 약관을 변경할 수 있으며, 변경된 약관은 전항과 같은 방법으로 효력이 발생됩니다.
                </li>
            </ol>

            <h4 id="제3조">제3조 (약관 외 준칙)</h4>
            <p>
            이 약관에 명시되지 않은 사항은 관계 법령의 규정에 따릅니다.
            </p>

            <h4 id="제4조">제4조 (용어의 정의)</h4>
            <p>
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            </p>
            <ol>
                <li>
                    1. 이용자: 회사와 서비스 이용계약을 체결한 실명의 개인 및 법인 또는 법인에 준하는 단체를 말합니다.
                </li>
                <li>
                    2. 회원 ID: 이용자 식별과 서비스 이용을 위하여 이용자가 선정하고 회사가 부여하는 영문자 혹은 영문자와 숫자의 조합을 말합니다.
                </li>
                <li>
                    3. 비밀번호: 서비스 이용 시 회원 ID와 이용자가 일치하는지를 확인하고 이용자의 비밀보호를 위하여 이용자 자신이 선정한 영문자 혹은 영문자와 숫자의 조합을 말합니다.
                </li>
            </ol>

            <h3 id="제2장_회원_가입과_서비스_이용">제2장 회원 가입과 서비스 이용</h3>

            <h4 id="제5조">제5조 (이용 계약의 성립)</h4>  
            <ol>
                <li>
                    1. 이용 계약은 이용자의 이용 신청에 대한 회사의 이용 승낙과 이용자의 약관 내용에 대한 동의로 성립됩니다.
                </li>
                <li>
                    2. 회원에 가입하여 서비스를 이용하고자 하는 이용희망자는 회사에서 요청하는 소정의 개인 신상정보를 제공해야 합니다.
                </li>
                <li>
                    3. 회사는 다음 각 호에 해당하는 이용계약 신청에 대하여는 이를 승낙하지 않을 수 있습니다.
                    <ol>
                        <li>
                            1. 다른 사람의 명의를 사용하여 신청하였을 때
                        </li>
                        <li>
                            2. 이용 계약 신청서의 내용을 허위로 기재하였거나 허위서류를 첨부하여 신청하였을 때
                        </li>
                        <li>
                            3. 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때  
                        </li>
                    </ol>
                </li>
            </ol>

            <h4 id="제6조">제6조 (서비스 이용)</h4>
            <ol>
                <li>
                    1. 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
                </li>
                <li>
                    2. 제1항의 이용시간은 정기점검 등의 필요로 인하여 회사가 정한 날 또는 시간에는 예외로 합니다.
                </li>
                <li>
                    3. 회원에 가입한 후라도 일부 서비스 이용 시 이용자의 연령 등 회사가 정하는 사유에 따라 서비스 이용을 제한할 수 있습니다.  
                </li>
            </ol>

            <h4 id="제7조">제7조 (서비스 제공의 중지)</h4>
            <p>
            회사는 다음 사항을 이유로 서비스 제공을 중지할 수 있습니다.
            </p>
            <ol>
                <li>
                    1. 회사가 사용하는 설비의 이전, 보수 또는 공사로 인해 부득이한 경우  
                </li>
                <li>
                    2. 전기통신사업법에 규정된 기간통신사업자가 회사에 대한 전기통신서비스를 중지하였을 경우
                </li>
                <li>
                    3. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 사용의 폭주 등의 사유로 정상적인 서비스 제공이 어려운 경우
                </li>
            </ol>

            <h3 id="제3장_책임">제3장 책임</h3>

            <h4 id="제8조">제8조 (회사의 의무)</h4>
            <ol>
                <li>
                    1. 회사는 특별한 사정이 없는 한 이용자가 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 합니다.  
                </li>
                <li>
                    2. 회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.
                </li>
                <li>
                    3. 회사는 이용자의 개인 신상 정보를 본인의 승낙 없이 타인에게 누설 또는 배포하여서는 안됩니다. 단, 전기통신관련법령 등 관계법령에 의하여 관계 국가기관 등의 요구가 있는 경우에는 예외로 합니다.
                </li>
                <li>
                    4. 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 이를 즉시 처리하여야 합니다. 단, 즉시 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 통보하여야 합니다.
                </li>
            </ol>

            <h4 id="제9조">제9조 (이용자의 의무)</h4>  
            <ol>
                <li>
                    1. 아이디와 비밀번호에 관한 모든 관리의 책임은 이용자에게 있습니다.
                </li>
                <li>
                    2. 자신의 아이디가 부정하게 사용된 경우, 이용자는 반드시 회사에 그 사실을 통보해야 합니다.
                </li>
                <li>
                    3. 이용자가 이용자 ID 및 비밀번호를 소홀히 관리하여 발생한 서비스 사용상의 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 사용자에게 있으며 회사는 그에 대한 책임을 지지 않습니다.
                </li>
                <li>  
                    4. 이용자는 저작권법에 따라 개인적인 용도가 아닌 경우 서비스 내에 게재된 회사의 저작물 일부 또는 전부를 전재하거나 복제할 수 없습니다.
                </li>
                <li>
                    5. 이용자는 이 약관 및 관계법령에서 규정한 사항을 준수하여야 합니다.
                </li>
            </ol>
            <h3 id="제4장_계약_해지_및_서비스_이용_제한">제4장 계약 해지 및 서비스 이용 제한</h3>
            <h4 id="제10조">제10조 (계약 해지 및 이용제한)</h4>
            <ol>
                <li>
                    1. 이용자가 이용 계약을 해지하고자 하는 때에는 이용자 본인이 온라인을 통해 "문의하기" 메뉴를 선택하여 해지 신청을 하여야 합니다. 회사는 이용자가 다음 사항에 해당하는 행위를 하였을 경우 사전 통지 없이 이용 계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.
                    <ol>
                        <li>
                            1. 공공 질서 및 미풍 양속에 반하는 경우  
                        </li>
                        <li>
                            2. 범죄적 행위에 관련되는 경우
                        </li>
                        <li>
                            3. 이용자가 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우  
                        </li>
                        <li>
                            4. 타인의 서비스 아이디 및 비밀번호를 도용한 경우
                        </li>
                        <li> 
                            5. 타인의 명예를 손상시키거나 불이익을 주는 경우
                        </li>
                        <li>
                            6. 같은 사용자가 다른 아이디로 이중 등록을 한 경우
                        </li>
                        <li>  
                            7. 서비스에 위해를 가하는 등 서비스의 건전한 이용을 저해하는 경우
                        </li>
                        <li>
                            8. 정보통신 윤리 위원회 등 권위있는 기관의 시정요구가 있을 경우 
                        </li>
                        <li>
                            9. 이용 계약 신청서의 내용을 허위로 기재하였을 경우
                        </li>
                        <li>  
                            10. 기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우
                        </li>
                    </ol>
                </li>
            </ol>

            <h4 id="제11조">제11조 (이용 제한의 해제 절차)</h4>
            <ol>
                <li>
                    1. 회사는 제10조의 규정에 의하여 이용정지 및 계약해지를 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 서면, 전자우편 또는 전화 등의 방법에 의하여 해당 이용자 또는 대리인에게 통지합니다. 다만, 회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니할 수 있습니다.   
                </li>
                <li>  
                    2. 제1항의 규정에 의하여 이용정지의 통지를 받은 이용자 또는 그 대리인은 이의신청을 할 수 있습니다.
                </li>
                <li>
                    3. 회사는 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용정지를 일시 연기할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.  
                </li>
                <li>
                    4. 회사는 이용정지 기간 중에 그 이용정지 사유가 해소된 것이 확인된 경우에는 이용 정지 조치를 즉시 해제합니다.
                </li>
            </ol>

            <h4 id="제12조">제12조 (이용자의 게시물)</h4>  
            <p>
            회사는 이용자가 게시하거나 등록하는 서비스 내의 내용물이 다음 각 사항에 해당한다고 판단되는 경우에 사전 통지 없이 삭제할 수 있습니다.
            </p>
            <ol>
                <li>
                    1. 다른 이용자 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우
                </li>
                <li>
                    2. 공공질서 및 미풍양속에 위반되는 내용인 경우
                </li>
                <li>
                    3. 범죄적 행위에 결부된다고 인정되는 내용일 경우
                </li>
                <li>
                    4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
                </li>
                <li>
                    5. 기타 관계 법령이나 회사에서 정한 규정에 위배되는 경우
                </li>
            </ol>

            <h4 id="제13조">제13조 (서비스 사용의 제한)</h4>
            <p>  
            이용자는 회사에서 허용한 경우를 제외하고는 서비스를 사용하여 영업 활동을 할 수 없습니다.  
            </p>

            <h3 id="제5장_정보의_제공">제5장 정보의 제공</h3>

            <h4 id="제14조">제14조 (정보의 제공)</h4>
            <p>
            회사는 이용자가 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 전자우편 등의 방법으로 이용자에게 제공할 수 있습니다.
            </p>

            <h4 id="제15조">제15조 (지적 재산권)</h4>
            <ol>
                <li>  
                    1. 서비스에 회원이 게재한 자료의 권리와 책임은 회원에게 있으며 회사는 회원의 동의 없이 이를 영리적으로 사용하지 않습니다. 단, 비영리적인 목적의 경우 서비스 내에 게재할 수 있습니다.
                </li>
                <li>
                    2. 회사가 지적 재산권을 가지고 있는 자료, 서비스, 소프트웨어, 상표 등을 회사의 사전 동의 없이 전부 또는 일부 수정, 대여, 배포, 제작, 양도, 재라이센스, 담보권 설정, 상업적 이용행위 등은 할 수 없습니다.   
                </li>
            </ol>

            <h4 id="제16조">제16조 (서비스 내용의 추가 및 변경)</h4>
            <p>  
            회사는 필요하다고 판단되는 경우 서비스 내용의 추가 및 변경을 할 수 있으며, 이로 인하여 이용자에게 추가로 주지해야 할 사항이 생긴 경우 회사는 그 내용을 공지합니다.
            </p>
            
            <h3 id="제6장_회사의_면책조항">제6장 회사의 면책조항</h3>
            
            <h4 id="제17조">제17조 (회사의 면책조항)</h4>
            <ol>
                <li>
                    1. 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다. 
                </li>
                <li>
                    2. 회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
                </li>
                <li>
                    3. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
                </li>
                <li>
                    4. 회사는 회원의 귀책사유로 인한 서비스 이용 장애 또는 손해에 대해 책임을 지지 않습니다. 
                </li>
                <li>
                    5. 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 발생한 경우 책임을 지지 않습니다.
                </li>
                <li>
                    6. 회사는 회원이 서비스를 이용하여 얻은 자료로 인한 손해에 대해 책임을 지지 않습니다. 또한 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대해 책임을 지지 않습니다.
                </li>
                <li>
                    7. 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.   
                </li>
                <li>  
                    8. 회사는 이용자 상호 간 또는 이용자와 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
                </li>
            </ol>

            <h3 id="제7장_기타사항">제7장 기타사항</h3>

            <h4 id="제18조">제18조 (재판권 및 준거법)</h4>
            <ol>
                <li>  
                    1. 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.
                </li>
                <li>
                    2. 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
                </li>
            </ol>

            <h4 id="제19조">제19조 (이용자의 개인정보보호)</h4>
            <p>
            회사는 관련법령이 정하는 바에 따라서 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위해 노력합니다. 이용자의 개인정보 보호에 관해서는 관련법령 및 회사가 정하는 "개인정보취급방침"에 정한 바에 의합니다.
            </p>
        </div>

        <div id="privacy" className="p-policy-header">
            <h2 className="p-policy-title">희망과학심리상담센터 개인정보 취급방침</h2>
            <div className="p-policy-date-container__outer">
            <div className="p-policy-date-container">
                <select className="p-policy-date-container-select">
                <option value="0">2024.06.13</option>
                </select>
            </div>
            </div>
        </div>

        <div className="p-policy-link-container">
            <div className="p-policy-link-container__inner">
                <ol>
                    <li>
                        <a href="#개인정보보호정책1">제 1조</a>
                    </li>
                    <li>
                        <a href="#개인정보보호정책2">제 2조</a>
                    </li>
                    <li>
                        <a href="#개인정보보호정책3">제 3조</a>
                    </li>
                    <li>
                        <a href="#개인정보보호정책4">제 4조</a>
                    </li>
                    <li>
                        <a href="#개인정보보호정책5">제 5조</a>
                    </li>
                    <li>
                        <a href="#개인정보보호정책6">제 6조</a>
                    </li>
                    <li>
                        <a href="#개인정보보호정책7">제 7조</a>
                    </li>
                </ol>
            </div>
        </div>

        <div className="p-policy-term-container">
            <p className="p-policy-term-description">
            희망과학심리상담센터의 개인정보 보호정책은 정보통신망이용촉진 등에 관한 법률 제16조 내지 제18조의 규정을 준수합니다. 
            </p>

            <p className="p-policy-term-description">
            이 문서는 2007년 07월 27일 개정된 정보통신망법에 따른 '개인정보취급방침'에 따라 작성되었습니다.
            </p>

            <p className="p-policy-term-description">
            희망과학심리상담센터에서는 회원의 개인 정보를 불법 유출되는 것을 방지하기 위해 다음과 같은 개인 정보 보호 정책을 취하고 있습니다.
            </p>
            <h4 id="개인정보보호정책1">제 1조</h4>
            <p>
            희망과학심리상담센터 홈페이지에서는 "가입신청" 양식에서 보실 수 있는 회원 약관(제2장 회원 가입과 서비스 이용, 제5조 이용 계약의 성립)을 통해 확인할 수 있으며, 웹사이트 운영에 필요한 최소한의 개인 정보를 요청하고 있습니다. 회원 여러분께서 제공한 개인 정보는 트랜스웹에서 서비스 제공과 이용에 투명성을 기하고, 회원 여러분께 더욱 유용한 정보를 전달해드리는 목적으로만 이용됩니다.
            </p>
            <h4 id="개인정보보호정책2">제 2조</h4>
            <p>
            희망과학심리상담센터 홈페이지에서는 절대 회원 본인의 승낙 없이 회원 여러분들의 개인 정보를 제3자에게 누설하거나 배포하지 않습니다. 단, 회원 약관(제2조, 제3항)에 의거하여, 전기통신 관련 법령에 저촉되거나 수사상의 목적으로 인해 국가 기관의 요청이 있을 경우, 적절한 절차에 따라 회원 여러분의 개인 정보가 제공될 수 있습니다. 그리고, 홈페이지 이용자 현황 조사 또는 이벤트 당첨자 발표 등의 목적으로 회원 여러분의 정보가 이용될 수 있지만, 이외의 어떠한 경우에도 외부에 신상을 정보가 고의로 누출되는 일은 없습니다.
            </p>
            <h4 id="개인정보보호정책3">제 3조</h4>
            <p>
                회원 여러분의 개인 정보는 회원께서 회원 가입 약관에 동의하여 가입한 시각부터 희망과학심리상담센터 홈페이지에서 관리되며, 회원 여러분이 고객센터를 통해 해지 요청을 보내면 적절한 확인 절차를 통해 삭제됩니다.
            </p>
            <h4 id="개인정보보호정책4">제 4조</h4>
            <p>
                최근 서비스 제공자의 웹사이트 서버가 이용자의 컴퓨터로 전송하는 정보 파일로서, 방문한 웹사이트의 정보 및 이용자가 개인 정보 입력 없이 암호화되어 담겨 있는 쿠키(cookie)가 개인 정보 침해의 수단으로 사용될 수 있다는 논란이 일고 있습니다. 희망과학심리상담센터 홈페이지에서는 쿠키 파일을 사용하지 않기 때문에, 본인도 모르는 사이에 회원 여러분의 컴퓨터로부터 개인 정보가 누출되거나, 컴퓨터에 로그된 정보가 남겨지는 일이 절대 발생하지 않습니다. 
            </p>
            <h4 id="개인정보보호정책5">제 5조</h4>
            <p>
                희망과학심리상담센터에서는 국내 최고의 네트워크 보안 전문가의 협력 하에 서버를 관리하고 있으므로 해킹 등에 의해 회원 정보가 누출되는 일이 없으며, 관련 책임자와 담당자들은 불필요한 회원 정보 접근을 억제함으로써 최소한의 정보만을 열람하며, 개인 정보 보호 정책에 준수에 만전을 기하고 있습니다.
            </p>
            <h4 id="개인정보보호정책6">제 6조</h4>
            <p>
                회원 정보를 분실한 경우에는, 회원 로그인(log-in) 창에서 자신의 주민등록 번호를 입력함으로써 재확인할 수 있습니다. 불가한 경우에는, 자신의 성명, 주민등록번호, 이메일 주소를 적어 관리자(hopescience0110@naver.com)에게 메일을 주시기 바랍니다. 전화로는 회원 비밀번호를 문의하시는 경우, 본인 확인이 어렵고 누구나 열람할 수 있으므로, 전화로는 비밀번호를 알려드리지 않습니다.
            </p>
            <h4 id="개인정보보호정책7">제 7조</h4>
            <p>
                자신의 아이디가 부정하게 사용된 경우, 반드시 관리자에게 그 사실을 통보해야 적절한 조치가 취해질 수 있습니다. 또한, 회원 ID 및 비밀번호를 남에게 빌려주거나 소홀한 관리에 의해 문제가 발생하지 않도록 회원 여러분의 각별한 주의를 요청드립니다.   
            </p>
        </div>

        <div id="refund" className="p-policy-header">
            <h2 className="p-policy-title">희망과학심리상담센터 환불정책</h2>
            <div className="p-policy-date-container__outer">
            <div className="p-policy-date-container">
                <select className="p-policy-date-container-select">
                <option value="0">2024.06.13</option>
                </select>
            </div>
            </div>
        </div>
        <div className="p-policy-link-container">
            <div className="p-policy-link-container__inner">
                <ol>
                    <li>
                        <a href="#환불정책1">전액 환불 가능 기간</a>
                    </li>
                    <li>
                        <a href="#환불정책2">부분 환불 제한 조건</a>
                    </li>
                    <li>
                        <a href="#환불정책3">기술적 문제로 인한 환불</a>
                    </li>
                    <li>
                        <a href="#환불정책4">환불 방식</a>
                    </li>
                    <li>
                        <a href="#환불정책5">환불 처리 기간</a>
                    </li>
                    <li>
                        <a href="#환불정책6">구매 증빙 자료</a>
                    </li>
                    <li>
                        <a href="#환불정책7">문제 해결 시도</a>
                    </li>
                    <li>
                        <a href="#환불정책8">서비스 중단 시 환불</a>
                    </li>
                    <li>
                        <a href="#환불정책9">기술적 문제로 인한 이용 불가 시 환불</a>
                    </li>
                    <li>
                        <a href="#환불정책10">서비스 기간 시작 전 환불</a>
                    </li>
                </ol>
            </div>
        </div>

        <div className="p-policy-term-container">
            <h4 id="환불정책1">1. 전액 환불 가능 기간</h4>
            <p>
            강의 구매 후 7일 이내에는 어떠한 이유로든 전액 환불이 가능합니다.
            </p>
            <h4 id="환불정책2">2. 부분 환불 제한 조건</h4>
            <p>
            7일이 지난 후에는 강의 접근 여부와 진행 정도에 따라 환불이 제한될 수 있습니다. 예를 들어, 강의의 50% 이상을 수강한 경우 환불이 불가능할 수 있습니다.
            </p>
            <h4 id="환불정책3">3. 기술적 문제로 인한 환불</h4>
            <p>
            기술적 문제로 인한 강의 접근 실패 시, 문제 해결 후 추가적인 서비스 기간을 제공하거나, 환불을 진행할 수 있습니다.
            </p>
            <h4 id="환불정책4">4. 환불 방식</h4>
            <p>
            환불은 원래 결제 방식으로만 처리됩니다.
            </p>
            <h4 id="환불정책5">5. 환불 처리 기간</h4>
            <p>
            환불 처리 기간은 요청일로부터 최대 14일 이내입니다.
            </p>
            <h4 id="환불정책6">6. 구매 증빙 자료</h4>
            <p>
            환불 요청 시 구매 증빙 자료는 필요하지 않습니다.
            </p>
            <h4 id="환불정책7">7. 문제 해결 시도</h4>
            <p>
            강의 내용의 오류나 불만족스러운 점이 있을 경우, 먼저 고객 지원 센터를 통해 문제 해결을 시도해야 합니다.   
            </p>
            <h4 id="환불정책8">8. 서비스 중단 시 환불</h4>
            <p>
            강의가 서비스 중단된 경우, 해당 기간에 비례하여 환불이 이루어집니다. 
            </p>
            <h4 id="환불정책9">9. 기술적 문제로 인한 서비스 이용 불가 시 환불</h4>
            <p>
            강의 이용 중 발생한 기술적 문제로 인해 서비스를 제대로 이용하지 못한 경우, 해당 기간에 비례하여 환불 또는 서비스 기간 연장을 요청할 수 있습니다.  
            </p>
            <h4 id="환불정책10">10. 서비스 기간 시작 전 환불</h4>
            <p>
            강의 구매 후 서비스 기간이 시작되기 전에는 언제든지 환불이 가능합니다. 
            </p>
        </div>

        <div id="service_period" className="p-policy-header">
            <h2 className="p-policy-title">희망과학심리상담센터 서비스 제공기간 안내</h2>
            <div className="p-policy-date-container__outer">
            <div className="p-policy-date-container">
                <select className="p-policy-date-container-select">
                <option value="0">2024.06.18</option>
                </select>
            </div>
            </div>
        </div>

        <div className="p-policy-term-container">
            <h3>서비스 제공 기간</h3>

            <h4>1. 서비스 제공기간</h4>
            <p>
            서비스 이용은 희망과학심리상담센터의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다.
            다만, 희망과학심리상담센터는 시스템 정기점검, 증설 및 교체를 위해 희망과학심리상담센터가 정한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시중단은 희망과학심리상담센터가 제공하는 서비스를 통해 사전에 공지합니다. 
            </p>

            <h4>2. 서비스 제공의 중단</h4>
            <ol>
                <li>  
                    ① 정보통신설비의 보수 점검, 교체 및 고장 등 공사로 인한 부득이 한 경우
                </li>
                <li>
                    ② 기간통신사업자가 전기통신서비스를 중단했을 경우
                </li>
                <li>
                    ③ 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
                </li>
                <li>
                    ④ 국가비상사태 등 기타 불가항력적인 사유가 있는 경우
                </li>
            </ol>
            <p>
            전항에 의한 서비스 중단의 경우 희망과학심리상담센터는 1에 정한 방법으로 그 사유 및 기간 등을 공지합니다. 다만, 희망과학심리상담센터가 통제할 수 없는 사유로 발생한 서비스의 중단(시스템관리자의 고의, 과실없는 디스크 장애, 시스템 다운 등)에 대하여 사전공지가 불가능한 경우에는 예외로 합니다.
            </p>
        </div>
    </main>
    )
}